export const LIMIT_EXCEEDS_MESSAGE: string =
  'You reached the limit you have to register/login to generate more taglines'
export const NO_CREDIT_POINTS_MESSAGE: String =
  "You don't have any credit points"
export const BOOKMARK_ADDED_SUCCESSFULLY: String =
  'Added to Bookmarks successfully'
export const SOMETHING_WENT_WRONG: String = 'Something went wrong'
export const INITIAL_COUNT = 59
export const STATUS = {
  STARTED: 'Started',
  STOPPED: 'Stopped',
}
