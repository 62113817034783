import { configureStore } from '@reduxjs/toolkit'
import buttonEffectSlice from '../slices/ButtonEffectSlice'
import rangeSliderSlice from '../slices/RangeSliderSlice'
import generateHeadlineSlice from '../slices/GenerateHeadlineSlice'
import pointsSlice from '../slices/PointsSlice'
import profileSlice from '../slices/ProfileSlice'
import loginSlice from '../slices/auth/LoginSlice'
import otpTimerSlice from '../slices/OtpTimerSlice'
import savedRecordSlice from '../slices/SavedRecordSlice'
import counterSlice from '../slices/CounterSlice'
import contactusSlice from '../slices/auth/ContactUsSlice'
import registerSlice from '../slices/auth/RegisterSlice'
import forgotPasswordSlice from '../slices/auth/ForgotPasswordSlice'

const rootReducer = {
  buttonEffectSlice,
  rangeSliderSlice,
  generateHeadlineSlice,
  counterSlice,
  loginSlice,
  registerSlice,
  forgotPasswordSlice,
  savedRecordSlice,
  pointsSlice,
  profileSlice,
  contactusSlice,
  otpTimerSlice,
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddlerWare) =>
    getDefaultMiddlerWare({
      serializableCheck: false,
    }),
  devTools: true,
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export default store
